<template>
  <div class="items-consumption">
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.Word"
          placeholder="请输入单号/姓名/手机号/卡号"
          size="mini"
          clearable
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAdvanceSearch"
          >高级查询</el-button
        >
      </div>

      <div class="df-col">
        <DfExportButton
          ref="dfExportButton"
          :fileName="fileName"
          :checkAllow="false"
          @export="onExportExcel"
        ></DfExportButton>
      </div>
    </div>

    <!-- start 项目分类统计明细表 -->
    <el-table
      class="items-table"
      v-loading="loading"
      :data="tableList"
      :max-height="tableMaxHeight"
      :span-method="objectSpanMethod"
      stripe
      border
    >
      <el-table-column
        prop="BillNo"
        label="单号"
        align="center"
        width="140"
      ></el-table-column>
      <el-table-column prop="" label="消费时间" align="center" width="160">
        <template slot-scope="scope">
          {{
            scope.row.CheckoutTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.CheckoutTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="客户" align="center">
        <template slot-scope="scope">
          <div class="member-box">
            <span>{{ scope.row.MemberName }}</span>
            <span>{{ scope.row.BillSex ? "男" : "女" }}</span>
            <span>{{ scope.row.MemberPhone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="计客数" align="center" width="90">
        <template slot-scope="scope">
          <div class="calc-box">
            <span class="color pointer">{{ scope.row.CalcClientText }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="消费金额" align="center">
        <template slot-scope="scope">
          <div class="amount-box">
            <template
              v-if="
                scope.row.BillAmount ||
                  scope.row.BillDisc ||
                  scope.row.BillCoupon
              "
            >
              <span v-show="scope.row.BillAmount"
                >总金额：{{ scope.row.BillAmount }}</span
              >
              <span v-show="scope.row.BillDisc"
                >折扣：{{ scope.row.BillDisc }}</span
              >
              <span v-show="scope.row.BillCoupon"
                >优惠券：{{ scope.row.BillCoupon }}</span
              >
              <span class="line"></span>
            </template>

            <div
              class="payment-info"
              v-for="(item, index) in scope.row.PaymentInfoList"
              :key="item.PaymentGuid"
            >
              <span
                class="payment-money color"
                :class="item.PaymentType == 1 ? 'active' : ''"
                >{{ item.PayMoney }}</span
              >
              <span :class="item.PaymentType == 1 ? 'active' : ''">{{
                item.PaymentName
              }}</span>
            </div>
            <div
              class="vip-items-info"
              v-for="(item, index) in scope.row.VipItemCardList"
              :key="item.VipGuid"
            >
              <span>{{ item.CardName }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="消费内容" align="center" width="220">
        <template slot-scope="scope">
          <div class="items-box">
            <el-table
              v-if="scope.row.ItemInfoList.length > 0"
              :data="scope.row.ItemInfoList"
              :show-header="false"
              :border="false"
              :span-method="objectSpanMethodItem"
              class="custom-table"
              v-loading="loading"
            >
              <el-table-column align="center" width="220">
                <template slot-scope="scope1">
                  <div>
                    <div>
                      <span>{{ scope1.row.ItemName }}</span>
                      <span> ¥{{ scope1.row.ItemAmount }}</span>
                    </div>
                    <span class="color" v-show="scope1.row.ItemTypeText">
                      ({{ scope1.row.ItemTypeText }})
                    </span>
                    <span class="dept-name">
                      {{ scope1.row.DeptName || "无部门" }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="80">
                <template slot-scope="scope2">
                  <div class="three-level">
                    <el-table
                      v-if="scope2.row.EmplInfoList.length > 0"
                      class="three-table"
                      :data="scope2.row.EmplInfoList"
                      :show-header="false"
                      :border="false"
                    >
                      <el-table-column
                        align="center"
                        prop="PerforFinal"
                        width="80"
                      >
                      </el-table-column>
                      <el-table-column
                        align="center"
                        prop="RoyaFinal"
                        width="80"
                      >
                      </el-table-column>
                      <el-table-column align="center" width="150">
                        <template slot-scope="scope3">
                          <div class="empl-box">
                            <div>
                              <span class="empl-no">{{
                                scope3.row.EmplNo
                              }}</span>
                              <span>{{ scope3.row.LevelName }}</span>
                            </div>
                            <span>[{{ scope3.row.EmplName }}]</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" width="80">
                        <template slot-scope="scope3">
                          <div class="assign-box">
                            <span>
                              {{
                                scope3.row.AssignMark == 1 ? "指定" : "非指定"
                              }}
                            </span>
                            <span>
                              {{ scope3.row.AssignRoyaFinal }}
                            </span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column align="center">
                        <template slot-scope="scope3" width="80">
                          {{ scope3.row.InventRoyaFinal }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="180">
                <template slot-scope="scope">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
              <el-table-column align="center" width="80">
                <template slot-scope="scope">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template slot-scope="scope" width="80">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="业绩" align="center" width="80">
      </el-table-column>
      <el-table-column label="提成" align="center" width="80">
      </el-table-column>
      <el-table-column label="员工" align="center" width="180">
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot="header" slot-scope="scope">
          <div>指定</div>
          <div>提成</div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot="header" slot-scope="scope">
          <div>创单</div>
          <div>提成</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="" label="业绩"></el-table-column>
      <el-table-column prop="" label="提成"></el-table-column>
      <el-table-column prop="" label="员工"></el-table-column>
      <el-table-column prop="" label="指定提成"></el-table-column>
      <el-table-column prop="" label="创单业绩"></el-table-column> -->
      <el-table-column
        prop="OperatorName"
        label="操作人"
        align="center"
        width="90"
      ></el-table-column>
    </el-table>
    <!-- end 项目分类统计明细表 -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      :current-page="pageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <AdvancedQuery
      ref="AdvancedQuery"
      v-model="submitData"
      @search="onSearch"
    ></AdvancedQuery>
  </div>
</template>

<script>
import Report from "@/api/report.js";

import AdvancedQuery from "./components/advance-query-dialog.vue";

export default {
  components: { AdvancedQuery },

  data() {
    return {
      tableMaxHeight: "",
      fileName: "",
      totalRecord: 0,
      pageIndex: 1,
      pageSize: 10,
      multipleDate: [],
      submitData: {
        BeginDate: "",
        EndDate: "",
        BeginBillNo: "",
        EndBillNo: "",
        Word: "",
        MemberType: "",
        PaymentGuid: "",
        NatureNo: "",
        CardGuid: "",
        CheckoutAdmin: "",
        ItemGuid: "",
        DutyNo: "",
        LevelGuid: "",
        EmplGuid: "",
      },
      billList: [],
      tableList: [],
      loading: false,
      isCheckEmpl: false,
    };
  },
  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.queryWorkSellBillRecord();
    await this.onPaging();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 查询项目消费数据
    async queryWorkSellBillRecord(isExport = 0) {
      this.loading = true;
      try {
        let { data } = await Report.queryWorkSellBillRecord(
          this.submitData,
          isExport
        );
        if (isExport) {
          return (this.fileName = data);
        }
        this.billList = data.BillList;
        this.totalRecord = data.BillList.length;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    onExportExcel() {
      this.queryWorkSellBillRecord(1);
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 假分页
    onPaging() {
      let billList = JSON.parse(JSON.stringify(this.billList));
      let pageSize = this.pageSize;
      let pageIndex = this.pageIndex;
      let start = (pageIndex - 1) * pageSize;
      let end = pageIndex * pageSize;
      let list = billList.slice(start, end);
      this.tableList = list;
      console.log(this.tableList);
    },

    async onSearch() {
      this.pageIndex = 1;
      await this.queryWorkSellBillRecord();
      await this.onPaging();
    },

    // 打开更多查询对话框
    onAdvanceSearch() {
      this.$refs.AdvancedQuery.onShowDialog();
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //return this.twoDimensionalArray(rowIndex, columnIndex);

      if (columnIndex == 5) {
        return {
          rowspan: 1, //rowIndex % 2 == 0 ? 2 : 0,
          colspan: 6, //rowIndex % 2 == 0 ? 5 : 0
        };
      } else if (columnIndex > 5 && columnIndex < 11) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },

    objectSpanMethodItem({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        return {
          rowspan: 1, //rowIndex % 2 == 0 ? 2 : 0,
          colspan: 4, //rowIndex % 2 == 0 ? 5 : 0
        };
      } else if (columnIndex > 1) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.onPaging();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.onPaging();
    },
  },
};
</script>

<style lang="less" scoped>
.items-consumption {
  .df-row {
    margin-top: 20px;

    .df-col {
      .el-input {
        width: 200px;
      }
    }
  }

  .items-table {
    ::v-deep .cell {
      padding: 0;
    }

    ::v-deep tbody > tr {
      td:nth-child(6) {
        padding: 0;
        > .cell {
          min-height: 60px;
        }
      }
    }

    ::v-deep tr {
      &:last-child {
        td:last-child {
          border-right: 0;
        }
      }
      td:last-child {
        // border-bottom: 0;
        border-right: 0;
      }
    }

    ::v-deep .custom-table {
      height: 100%;
      min-height: 60px;
      border-right: 0 !important;
      border-bottom: 0 !important;

      &::before {
        display: none;
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
        min-height: 60px;
      }
      > .el-table__body-wrapper {
        > table > tbody > tr {
          &:last-child {
            > td {
              border-bottom: 0;
            }
            > td:last-child {
              border-right: 0;
              .three-table {
                border-bottom: 0 !important;
              }
            }
          }
          td {
            padding: 0;
          }
          > td:last-child {
            border-bottom: 0;
          }
        }
      }

      .cell {
        height: 100%;

        > div {
          .flex-col;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }

      .second-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr:last-child
        > td {
        border-bottom: 0;
      }

      .second-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr
        > td:last-child,
      .three-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr
        > td:last-child {
        border-right: 0;
      }
    }

    ::v-deep .three-table {
      height: 100%;
      border-right: 0 !important;

      &::before {
        display: none;
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
        min-height: 60px;
      }
      > .el-table__body-wrapper {
        > table > tbody > tr {
          &:last-child {
            > td {
              border-bottom: 0;
            }
            > td:last-child {
              border-right: 0;
              .three-table {
                border-bottom: 0 !important;
              }
            }
          }
          td {
            padding: 0;
          }
          > td:last-child {
            // border-bottom: 0;
          }
        }
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
      }
      .cell {
        .flex-col;
        justify-content: center;
        height: 100%;

        > div {
          .flex-col;
          justify-content: center;
          height: 100%;

          .empl-no {
            margin-right: 10px;
          }
        }
      }
    }

    .member-box,
    .calc-box,
    .amount-box {
      .flex-col;
    }

    .line {
      width: 100%;
      border-bottom: 1px dashed #ccc;
    }
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>
