<template>
  <div>
    <el-dialog
      title="高级查询"
      :visible.sync="showDialog"
      width="750px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="90px" :inline="false" size="mini">
          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="查询关键字">
                <el-input
                  v-model="submitData.Word"
                  placeholder="请输入单号/姓名/手机号/卡号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="顾客类型">
                <el-select v-model="submitData.MemberType" clearable>
                  <el-option
                    v-for="item in memberType"
                    :key="item.Value + 1"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="消费部门">
                <el-select v-model="submitData.dept_guid" clearable>
                  <el-option
                    v-for="item in deptList"
                    :key="item.DeptGuid"
                    :label="item.DeptName"
                    :value="item.DeptGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="消费类型">
                <el-select v-model="submitData.bill_type" clearable>
                  <el-option
                    v-for="item in billTypeList"
                    :key="'type' + item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row> -->

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="起止单号">
                <div class="begin-end-bill">
                  <el-input
                    v-model="submitData.BeginBillNo"
                    placeholder="请输入起单号"
                  ></el-input>
                  <span class="line"> - </span>
                  <el-input
                    v-model="submitData.EndBillNo"
                    placeholder="请输入止单号"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="付款方式">
                <el-select v-model="submitData.PaymentGuid" clearable>
                  <el-option
                    v-for="item in paymentSelector"
                    :key="item.PaymentGuid"
                    :label="item.PaymentName"
                    :value="item.PaymentGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="卡属性">
                <el-select
                  v-model="submitData.NatureNo"
                  clearable
                  @change="
                    onChangeHandle(
                      $event,
                      'NatureNo',
                      'cardSelector',
                      'cardList',
                      'CardGuid',
                      'CardName'
                    )
                  "
                >
                  <el-option
                    v-for="item in natureSelector"
                    :key="item.NatureNo"
                    :label="item.NatureName"
                    :value="item.NatureNo"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="卡类型">
                <el-select v-model="submitData.CardGuid" clearable>
                  <el-option
                    v-for="item in cardList"
                    :key="item.CardGuid"
                    :label="item.CardName"
                    :value="item.CardGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="colSpan">
              <el-form-item label="跨店消费">
                <el-select v-model="submitData.stores_share" clearable>
                  <el-option
                    v-for="item in storesShareList"
                    :key="'stores' + item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->

            <!-- <el-col :span="colSpan">
              <el-form-item label="单据类型">
                <el-select v-model="submitData.bill_type" clearable>
                  <el-option
                    v-for="item in billTypeList"
                    :key="'type' + item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="员工职位">
                <el-select
                  v-model="submitData.DutyNo"
                  clearable
                  @change="
                    onChangeHandle(
                      $event,
                      'DutyNo',
                      'levelSelector',
                      'levelList',
                      'LevelGuid',
                      'LevelName'
                    )
                  "
                >
                  <el-option
                    v-for="item in dutySelector"
                    :key="item.DutyNo"
                    :label="item.DutyName"
                    :value="item.DutyNo"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="员工级别">
                <el-select
                  v-model="submitData.LevelGuid"
                  clearable
                  @change="
                    onChangeHandle(
                      $event,
                      'LevelGuid',
                      'employeeSelector',
                      'emplList',
                      'EmplGuid',
                      'EmplName'
                    )
                  "
                >
                  <el-option
                    v-for="item in levelList"
                    :key="item.LevelGuid"
                    :label="item.LevelName"
                    :value="item.LevelGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="colSpan">
              <el-form-item label="账单类型">
                <el-select v-model="submitData.abnormal_tpye">
                  <el-option
                    v-for="item in exceptionSheetQuery"
                    :key="'es' + item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="colSpan">
              <el-form-item label="是否对单">
                <el-select v-model="submitData.auditing" clearable>
                  <el-option
                    v-for="item in checkMarkList"
                    :key="'mark' + item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-row :gutter="rowSpan">
            <!-- <el-col :span="colSpan">
              <el-form-item label="账单类型">
                <el-select v-model="submitData.abnormal_tpye">
                  <el-option
                    v-for="item in exceptionSheetQuery"
                    :key="'es' + item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->

            <el-col :span="colSpan">
              <el-form-item label="服务员工">
                <el-select
                  v-model="submitData.EmplGuid"
                  value-key="Key"
                  @change="onChangeEmpl"
                >
                  <el-option
                    v-for="item in emplList"
                    :key="item.EmplGuid"
                    :label="item.EmplName"
                    :value="item.EmplGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="操作人">
                <el-select v-model="submitData.CheckoutAdmin" clearable>
                  <el-option
                    v-for="item in managerSelector"
                    :key="item.ManagerGuid"
                    :label="item.ManagerName"
                    :value="item.ManagerGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <!-- <el-col :span="colSpan">
              <el-form-item label="排序">
                <el-select
                  v-model="submitData.empl_guid"
                  value-key="Key"
                  @change="onChangeEmpl"
                >
                  <el-option
                    v-for="item in exceptionSheetQuery"
                    :key="'es' + item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->

            <el-col :span="colSpan">
              <el-form-item label="消费项目">
                <div class="item-box">
                  <span v-show="selectedItem.ItemName">{{
                    selectedItem.ItemName
                  }}</span>
                  <el-button type="primary" size="mini" @click="onShowItems"
                    >选择项目</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="colSpan">
              <el-form-item class="other-box" label="其他">
                <el-checkbox v-model="isCheckEmpl" @change="onChangeCheckEmpl"
                  >仅显示此员工项目</el-checkbox
                >
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onSearch">确 定</el-button>
      </span>
    </el-dialog>

    <DFSelectItem
      ref="DFSelectItem"
      :isCancelSelected="false"
      @onItemSelected="onItemSelected"
    ></DFSelectItem>
  </div>
</template>

<script>
import Report from "@/api/report.js";

export default {
  model: {
    prop: "submitData",
    event: "change",
  },

  data() {
    return {
      rowSpan: 20,
      colSpan: 11,
      submitData: {
        BeginDate: "",
        EndDate: "",
        BeginBillNo: "",
        EndBillNo: "",
        Word: "",
        MemberType: "",
        PaymentGuid: "",
        NatureNo: "",
        CardGuid: "",
        CheckoutAdmin: "",
        ItemGuid: "",
        DutyNo: "",
        LevelGuid: "",
        EmplGuid: "",
      },
      selectedItem: {},
      cardSelector: [],
      dutySelector: [],
      employeeSelector: [],
      levelSelector: [],
      managerSelector: [],
      memberType: [],
      natureSelector: [],
      paymentSelector: [],
      cardList: [],
      levelList: [],
      emplList: [],
      showDialog: false,
    };
  },

  watch: {
    submitData: {
      handler(newVal) {
        // console.log(newVal);
        this.$emit("change", newVal);
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {},

  methods: {
    // 初始化项目消费
    async initWorkSellBillRecord() {
      try {
        let { data } = await Report.initWorkSellBillRecord();

        data.cardSelector.unshift({
          CardGuid: "",
          CardName: "全部",
          NatureNo: "",
        });
        data.dutySelector.unshift({ DutyName: "全部", DutyNo: "" });
        data.employeeSelector.unshift({
          EmplGuid: "",
          EmplName: "全部",
          LevelGuid: "",
        });
        data.levelSelector.unshift({
          DutyNo: "",
          LevelGuid: "",
          LevelName: "全部|",
        });
        data.managerSelector.unshift({ ManagerGuid: "", ManagerName: "全部" });
        data.memberType.unshift({ Name: "全部", Value: "" });
        data.natureSelector.unshift({ NatureName: "全部", NatureNo: "" });
        data.paymentSelector.unshift({ PaymentGuid: "", PaymentName: "全部" });

        this.cardList = [{CardGuid: '', CardName: '全部'}];
        this.levelList = [{LevelGuid: '', LevelName: '全部'}];
        this.emplList = [{EmplGuid: '', EmplName: '全部'}];
        this.cardSelector = data.cardSelector;
        this.dutySelector = data.dutySelector;
        this.employeeSelector = data.employeeSelector;
        this.levelSelector = data.levelSelector;
        this.managerSelector = data.managerSelector;
        this.memberType = data.memberType;
        this.natureSelector = data.natureSelector;
        this.paymentSelector = data.paymentSelector;
      } catch (err) {
        console.log(err);
      }
    },

    onSearch() {
      this.$emit("search");
      this.onCloseDialog()
    },

    onShowItems() {
      this.$refs.DFSelectItem.openSelectItemDialog(
        this.selectedItem.ItemGuid ? [this.selectedItem.ItemGuid] : []
      );
    },

    // 通用下拉框改变事件
    onChangeHandle(
      event,
      checkKeyName,
      sourceArrName,
      saveArrName,
      initKeyName,
      otherKeyName
    ) {
      // console.log(event, this[sourceArrName], this[saveArrName]);
      let arr = this[sourceArrName].filter(
        (item) => item[checkKeyName] == event
      );
      arr.unshift({[otherKeyName]: '全部', [initKeyName]: ''})
      this[saveArrName] = arr;
      this.submitData[initKeyName] = "";
    },

    // 选择员工改变事件
    onChangeEmpl(event) {
      // console.log(event);
      this.emplGuid = event.EmplGuid;
      this.levelGuid = event.LevelGuid;
    },

    // 消费项目选中事件
    onItemSelected(event) {
      this.selectedItem = event || {};
      this.submitData.ItemGuid = event.ItemGuid;
      this.$refs.DFSelectItem.closeSelectItemDialog();
      // console.log(this.selectedItem);
    },

    // 打开对话框
    onShowDialog() {
      this.initWorkSellBillRecord();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-form {
    .el-form-item {
      .el-input,
      .el-select {
        width: 100%;
      }
    }

    .begin-end-bill,
    .item-box {
      .flex-row;

      .line {
        padding: 0 5px;
      }
    }

    .item-box {
      span {
        margin-right: 10px;
      }
    }

    .other-box > .el-form-item__content {
      .flex-row;
      justify-content: unset;
      align-items: center;
      height: 100%;
    }
  }
}
</style>
